import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { media } from '../../theme/styled-theme';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const Root = styled.div`
  position: relative;
  font-family: ${props => props.theme.fontSourceSansPro};
  width: 100%;
  display: flex;
  align-items: flex-start;
  text-align: left;
  min-height: 40vw;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  font-weight: 700;

  ${media.forPhoneOnly`
    min-height: 240px;
  `}
`;

const BackgroundPicture = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  background: ${props => props.desktop
    ? `url("${props.desktop}") center center / cover`
    : '#cccccc'};
  width: 100%;
  min-height: 40vw;
  color: #ffffff;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  ${media.forPhoneOnly`
    min-height: 250px;
    background: ${props => `url("${props.mobile}") center center / cover`};
  `}

  ${media.forTabletPortraitUp`
    background: ${props => `url("${props.tablet}") center center / cover`};
  `}

  ${media.forDesktopUp`
    background: ${props => `url("${props.desktop}") center center / cover`};
  `}
`;

const Overlay = styled.div`
  position: absolute;

  background-image: linear-gradient(0deg,rgba(0,0,0,0) 3%,rgb(0,0,0,0.31) 100%);
  opacity: 0.6;
  height: 80%;
  width: 100%;
  left: 0;
  top: 0;
`;

const Title = styled.h1`
  font-size: 3.25rem;
  margin: 0 0 0.75rem 0;

  ${media.forPhoneOnly`
    font-size: 1.75rem;
    margin: 0 0 0.75rem;
  `}
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin: 0 0 1.5rem;

  ${media.forPhoneOnly`
    font-size: 1rem;
    margin: 0 0 0.5rem;
  `}
`;

const Flex = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const ContentWrap = styled.div`
  z-index: 1;
  border-radius: 2px;
  position: relative;

  ${media.forPhoneOnly`
    width: 100%;
    min-width: 100%;
    padding: 1rem 0;
  `}
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const HomeLandingSection = memo(({
  children,
  mobilePhoto,
  tabletPhoto,
  desktopPhoto
}) => {
  const {
    t,
  } = useTranslation('new-version');

  return (
    <Root data-cy="home-landing" data-testid="home-landing">
      <BackgroundPicture
        mobile={mobilePhoto}
        tablet={tabletPhoto}
        desktop={desktopPhoto}
      />
      <Overlay />
      <Flex className="container">
        <Title>{t('HOME.HERO.TITLE')}</Title>
        <Subtitle>{t('HOME.HERO.SUBTITLE')}</Subtitle>
        <ContentWrap>
          { children }
        </ContentWrap>
      </Flex>
    </Root>
  );
});

HomeLandingSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  mobilePhoto: PropTypes.string,
  tabletPhoto: PropTypes.string,
  desktopPhoto: PropTypes.string
};

HomeLandingSection.defaultProps = {
  children: null,
  mobilePhoto: '',
  tabletPhoto: '',
  desktopPhoto: ''
};

export default HomeLandingSection;
